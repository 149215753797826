import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d358863"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navContentThird pagePadding" }
const _hoisted_2 = { class: "pageTitle textColor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_keynoteDetail = _resolveComponent("keynoteDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.data) + " ", 1),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.detailData.name), 1),
    (_ctx.showDetail)
      ? (_openBlock(), _createBlock(_component_keynoteDetail, {
          key: 0,
          style: {"margin-top":"10px"},
          data: _ctx.detailData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}